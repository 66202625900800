.header {
    box-sizing: border-box;
    height: var(--header-height);
    display: grid;
    grid-template-columns: 20rem auto 1fr;

    --plate-background-color: var(--primary-color);
    --plate-color: var(--background-color);
}

.logo {
    --plate-border-radius: 0 0 10px 30px;
    height: 2rem;
}

.logo h2 {
    font-weight: 700;
}

.repoLink {
    --plate-border-radius: 0 0 0 10px;

    align-self: flex-start;
    justify-self: end;
    box-sizing: border-box;
    height: 2rem;
    width: 4rem;
    display: flex;
    justify-content: flex-end;
}

.repoLink a {
    display: flex;
    align-items: center;
}

.repoLink img {
    aspect-ratio: 1 / 1;
    height: 24px;
}

.screenLabel {
    align-self: flex-end;

    height: 2rem;
    width: fit-content;

    --plate-border-radius: 10px 30px 0 0;
}
