.content {
    font-family: 'Roboto Mono', 'monospace';
    font-weight: 400;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    height: 100%;
    min-height: 0;
}

.pipelineGroup {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}