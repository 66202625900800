.container {
    width: 100%;
    height: 100%;
    min-width: 0;
}

.pipelineDetailContainer {
    height: 100%;
    width: 100%;
    display: flex;
    gap: 0.25rem;
    justify-content: space-evenly;
}

.stage {
    width: 24ch;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.content {
    --line-height: 1.2em;
    padding: 0.25rem;
    display: flex;
    justify-content: space-between;
    line-height: var(--line-height);
    height: calc(var(--line-height) * 9);
}

.left,
.right {
    display: flex;
    flex-direction: column;
}

.left > div {
    padding-right: 0.25rem;
}


.heading {
    display: flex;
    justify-content: center;
    height: 1rem;
    width: 100%;
}

.bubble {
    background:
            linear-gradient(to top left,
                rgba(0,0,0,0) 0%,
                rgba(0,0,0,0) calc(50% - 0.8px),
                var(--primary-color) 50%,
                rgba(0,0,0,0) calc(50% + 0.8px),
                rgba(0,0,0,0) 100%),
            linear-gradient(to top right,
                rgba(0,0,0,0) 0%,
                rgba(0,0,0,0) calc(50% - 0.8px),
                var(--primary-color) 50%,
                rgba(0,0,0,0) calc(50% + 0.8px),
                rgba(0,0,0,0) 100%);
    box-sizing: border-box;
    border: 1px solid var(--primary-color);
}

.cycle {
    height: 1rem;
    width: 100%;
}

.a {
    background: aqua;
}

.b {
    background: red;
}

.c {
    background: yellow;
}

.d {
    background: lime;
}

.e {
    background: fuchsia;
}

.f {
    background: blue;
}

.g {
    background: white;
}

.h {
    background: darkgreen;
}

.i {
    background: grey;
}
