.content {
    width: fit-content;
    color: white;

    font-family: 'Roboto Mono', 'monospace';
    font-weight: 400;
    line-height: 1;

    min-height: 0;
    max-height: 12rem;
    overflow-y: auto;
}

.line {
    display: flex;
    gap: 0.5rem;
    padding-left: 0.25rem;
}

.separator {
    width: 0.1rem;
    background: var(--background-color-light);
}

.wordLine {
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.wordHighlight {
    background: white;
    color: var(--background-color-light);
}

.address {
    display: flex;
    align-items: center;
}

.word {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.125rem 0.25rem;
}

.a {
    background: aqua;
    color: var(--background-color);
}

.b {
    background: red;
    color: var(--accent-color-light);
}

.c {
    background: yellow;
    color: var(--background-color);
}

.d {
    background: lime;
    color: var(--background-color);
}

.e {
    background: fuchsia;
    color: var(--accent-color-light);
}

.f {
    background: blue;
    color: var(--accent-color-light);
}

.g {
    background: white;
    color: var(--background-color);
}

.h {
    background: darkgreen;
    color: var(--accent-color-light);
}

.i {
    background: grey;
    color: var(--accent-color-light);
}