.button {
    background: var(--button-background);
    color: var(--button-color);
    box-sizing: border-box;
    cursor: pointer;
    line-height: 1;
    font-weight: var(--button-font-weight, 400);
    font-family: var(--button-font-family, 'Roboto', 'sans-serif');
    font-size: var(--button-font-size, 1rem);
    white-space: nowrap;
    padding: var(--button-padding, 0.25rem 0.5rem);
    border: var(--button-border, 0);
    border-radius: var(--button-radius, 0);
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.button.vertical span {
    box-sizing: border-box;
    writing-mode: vertical-lr;
    text-orientation: upright;
}

.button:hover {
    border: var(--button-hover-border, 0);
    border-radius: var(--button-radius, 0);
    background: var(--button-hover-background);
    color: var(--button-hover-color);
}

.button:active {
    border: var(--button-active-border, 0);
    background: var(--button-active-background);
    color: var(--button-active-color);
}
