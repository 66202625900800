.content {
    --stage-size: 1.25rem;
    --stage-gap: 0.125rem;
    display: flex;
    gap: var(--stage-gap);
}

.stages {
    display: flex;
    flex-direction: column;
    gap: var(--stage-gap);
    padding-right: 0.25rem;
}

.stages > label,
.verticalPipeline > label {
    font-size: x-small;
    height: var(--stage-size);
    width: var(--stage-size);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.states {
    display: flex;
}

.verticalPipeline {
    display: flex;
    flex-direction: column;
    gap: var(--stage-gap);
}

.verticalPipeline > div {
    height: var(--stage-size);
    width: var(--stage-size);
}

.a {
    background: aqua;
}

.b {
    background: red;
}

.c {
    background: yellow;
}

.d {
    background: lime;
}

.e {
    background: fuchsia;
}

.f {
    background: blue;
}

.g {
    background: white;
}

.h {
    background: darkgreen;
}

.i {
    background: grey;
}

.blank {
    background:
            linear-gradient(to top left,
            rgba(0,0,0,0) 0%,
            rgba(0,0,0,0) calc(50% - 0.8px),
            var(--primary-color) 50%,
            rgba(0,0,0,0) calc(50% + 0.8px),
            rgba(0,0,0,0) 100%),
            linear-gradient(to top right,
            rgba(0,0,0,0) 0%,
            rgba(0,0,0,0) calc(50% - 0.8px),
            var(--primary-color) 50%,
            rgba(0,0,0,0) calc(50% + 0.8px),
            rgba(0,0,0,0) 100%);
    box-sizing: border-box;
    border: 1px solid var(--primary-color);
}