.plate {
    background: var(--plate-background-color);
    color: var(--plate-color);
    border-radius: var(--plate-border-radius);
    box-shadow: var(--plate-box-shadow);
    border: var(--plate-border);

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.plate.vertical {
    flex-direction: column;
    height: 100%;
}

.plate .content {
    box-sizing: border-box;
}

.plate.horizontal .content {
    width: 100%;
    padding: var(--plate-content-padding, 0 1rem);
}

.plate.vertical .content {
    height: 100%;
    padding: var(--plate-content-padding, 1rem 0);
}