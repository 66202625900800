
.content {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
    padding: 0 0.5rem;
}

.column {
    display: flex;
    justify-content: space-between;
    gap: 0.25rem;
    white-space: pre;
}

.registers {

}

.register {
    box-sizing: border-box;
    color: var(--primary-color-light);
}

.separator {
    width: 1px;
    background: linear-gradient(0, transparent 0, var(--primary-color) 50%, transparent 100%);
}