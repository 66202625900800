.App {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.content {
    box-sizing: border-box;
    min-height: 0;
    border-left: 1px solid var(--primary-color);
}

.fadeIn {
    opacity: 100%;
    animation: fadeIn 1s ease-in-out 1;
}

@keyframes fadeIn {
    from { opacity: 0; }
}