/*noinspection CssInvalidAtRule*/
@import-normalize;

:root {
    --header-height: 2.5rem;
    --status-bar-height: 1.5rem;
    --scroll-width: 0.5rem;
    --editor-width: 50ch;
}

@media (min-width: 1200px) {
    :root {
        --editor-width: 60ch;
    }
}

@media (min-width: 1920px) {
    :root {
        --editor-width: 80ch;
    }
}

html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', 'sans-serif';
    height: 100%;
    width: 100%;
    min-width: 1050px;
    min-height: 800px;
}

h1, h2, h3, h4, h5 {
    font-weight: 900;
}

.reset-margin-and-padding {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    color: #fff;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

#root {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}