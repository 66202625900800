.content {
    --button-background: var(--background-color);
    --button-color: var(--primary-color);
    --button-font-size: x-small;
    --button-border: 2px solid var(--background-color);

    --button-hover-border: 2px solid var(--button-background);
    --button-hover-background: var(--primary-color);
    --button-hover-color: var(--button-background);

    --button-active-border: 2px solid var(--background-color);
    --button-active-background: var(--button-background);
    --button-active-color: var(--primary-color);
    --button-group-padding: 0.125rem;


    display: grid;
    grid-template-columns: var(--editor-width) 2px 2rem auto;
    box-sizing: border-box;
    height: 100%;
    min-height: 0;
}

.vertSpacer {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, transparent 0, var(--primary-color) 50%, transparent 100%);
}

.buttons {
    box-sizing: border-box;
    border-radius: 0 10px 10px 0;
    align-self: flex-start;
    width: 100%;
    height: fit-content;
    min-height: 0;
    display: flex;
    justify-content: center;
    background: var(--primary-color);
    margin-top: 2rem;
    padding: 1rem 0.25rem;
}
