.container {
    height: 100%;
    width: 100%;
    min-height: 0;
    min-width: 0;
}

.editor {
    height: 100%;
    width: 100%;
}