.container {
    display: flex;
    align-items: center;
    height: 100%;
}

.heading {
    box-sizing: border-box;
    padding-right: 0.5rem;
}

.heading h4 {
    font-family: 'Roboto', 'monospace';
    font-weight: 400;
    font-size: xx-small;
    text-transform: uppercase;
    writing-mode: vertical-lr;
    text-orientation: upright;
}

.content {
    box-sizing: border-box;
    width: 100%;
    min-height: 0;
    height: 100%;
}