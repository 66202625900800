.content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loading {
    font-family: 'Helvetica', 'sans-serif';
    height: 100%;
    width: 80ch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    min-width: 0;
}

.hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

.meter {
    border-radius: 2px;
    height: 15px;
    width: 100%;
    position: relative;
    border: 1px solid var(--primary-color);
}

.bar {
    background: linear-gradient(90deg, var(--primary-color-dark) 50%, var(--primary-color));
    width: 100%;
    height: 100%;
    display: block;
    animation: expandWidth 2s ease-in-out 1;
}

.statusContainer {
    font-family: monospace;
    font-weight: 300;
    width: 100%;
    height: 30ch;
    min-height: 0;
    display: grid;
    grid-template-columns: 2px auto 2px;
    gap: 1rem;
    overflow: hidden;
}

.statusText {
    height: 100%;
    overflow: hidden;
    margin: 0;
    font-size: x-small;
}

.fakeContentContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.fakeContent {
    height: 0.25rem;
    width: 50%;
    background: var(--primary-color-dark);
}

.fakeContent.lightColor {
    background: var(--primary-color);
}

.fakeContent.normalColor {
    background: var(--primary-color);
}

.fakeContent.darkColor {
    background: var(--primary-color-dark);
}

.vertSpacer {
    background: linear-gradient(0deg, transparent 0, var(--primary-color),  transparent 100%);
    height: 100%;
    width: 1px;
}


@keyframes expandWidth {
    from { width: 0; }
}

@keyframes circularProgress {
    0% {
        background: conic-gradient(var(--primary-color) 0, var(--background-color-light) 0deg);
    }
    25% {
        background: conic-gradient(var(--primary-color) 90deg, var(--background-color-light) 0deg);
    }
    50% {
        background: conic-gradient(var(--primary-color) 180deg, var(--background-color-light) 0deg);
    }
    100% {
        background: conic-gradient(var(--primary-color) 270deg, var(--background-color-light) 0deg);
    }
}