.statusBar {
    height: var(--status-bar-height);
    width: 100%;
    box-sizing: border-box;

    --plate-background-color: var(--primary-color);
    --plate-color: var(--background-color);
}

.poweredByLabel {
    height: 1.5rem;
    width: fit-content;

    --plate-border-radius: 0 0 30px 10px;
}
