.console {
    --widget-width: 100%;
}

.content {
    height: 15ch;
    overflow: auto;
    font-family: 'Roboto Mono', 'sans-serif';
    font-weight: 400;
    width: 100%;
}

.line {
    text-wrap: none;
    line-height: 1;
}