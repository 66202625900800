.container {
    padding-left: 1rem;
    min-width: 0;
    min-height: 0;
    overflow-y: auto;
}

.space {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: smaller;
}


.hart {
    display: flex;
    flex-direction: column;
}

.peripheralsWrapper {
    min-height: 0;
    width: 100%;
}

.peripherals {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.peripherals .memory {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}